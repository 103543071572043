import Utils from "@/utilities/utils";
import { setI18nLanguage } from "@/i18n";

const getDefaultState = () => {
  return {
    enterpriseId: null,
    enterpriseHasEsgModule: false,
    rootFirmId: null,
    firmHasEsgModule: false,
    roleId: null,
    rootFirm: null,
    referenceFirmId: null,
    referenceEnterpriseId: null,
    branchesTree: [],
    userId: null,
    userPermissions: null,
    userFullName: null,
    userToken: null,
    options: {
      activeLocale: "it",
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    userIsLogged: (state) => {
      return !!state.userToken;
    },
    userId: (state) => {
      return state.userId;
    },
    getUserEnterpriseId: (state) => {
      return state.referenceEnterpriseId;
    },
    getUserEnterpriseHasEsgModule: (state) => {
      return state.enterpriseHasEsgModule;
    },
    getUserFirmId: (state) => {
      return state.rootFirmId;
    },
    getUserFirmHasEsgModule: (state) => {
      return state.firmHasEsgModule;
    },
    getUserRootFirm: (state) => {
      return state.rootFirm;
    },
    getReferenceFirmId: (state) => {
      return state.referenceFirmId;
    },
    getReferenceEnterpriseId: (state) => {
      return state.referenceEnterpriseId;
    },
    getBranchesTree: (state) => {
      return state.branchesTree;
    },
    getUserRoleId: (state) => {
      return state.roleId;
    },
    userPermissions: (state) => {
      return state.userPermissions;
    },
    userFullName: (state) => {
      return state.userFullName;
    },
    initials: (state) => {
      let initials = "";
      if (state.userFullName) {
        let array = state.userFullName.split(" ");
        initials = `${array[0].slice(0, 1)} ${array[1].slice(0, 1)}`;
      }
      return initials;
    },
    getUserOptions: (state) => {
      return state.options;
    },
    getActiveLocale: (state) => {
      return state.options.activeLocale;
    },
    isImpersonateEnterprise: (state) => {
      return !!state.rootFirmId && !!state.referenceEnterpriseId;
    },
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userId = userData.id;
      state.userPermissions = Utils.convertPermissionsToArray(
        userData.userPermissions
      );
      state.userFullName = userData.fullName;
      state.userToken = userData.token;
      state.referenceEnterpriseId = userData.enterpriseId;
      state.rootFirmId = userData.firmId;
      state.roleId = userData.roleId;
      state.rootFirm = userData.rootFirm;
      state.referenceFirmId = userData.firmId;
      state.options = userData.options ?? {
        activeLocale: "it",
      };
    },
    ADD_BRANCH_TREE(state, branch) {
      state.branchesTree.push(branch);
    },
    SET_ENTERPRISE_HAS_ESG_MODULE(state, payload) {
      state.enterpriseHasEsgModule = payload;
    },
    SET_FIRM_HAS_ESG_MODULE(state, payload) {
      state.firmHasEsgModule = payload;
    },
    REMOVE_BRANCH_TREE(state, branch) {
      let idx = state.branchesTree.findIndex((br) => br.id === branch.id);
      state.branchesTree.splice(idx + 1, state.branchesTree.length - idx);
    },
    RESET_BRANCH_TREE(state) {
      state.branchesTree = [];
    },
    SET_USER_CC(state, cc) {
      state.userCC = cc;
    },
    SET_REFERENCE_FIRM_ID(state, firm) {
      state.referenceFirmId = firm;
    },
    SET_REFERENCE_ENTERPRISE_ID(state, enterprise) {
      state.referenceEnterpriseId = enterprise;
    },
    SET_USER_OPTIONS(state, options) {
      state.options = options;
    },
    SET_ACTIVE_LOCALE(state, activeLocale) {
      state.options.activeLocale = activeLocale;
      setI18nLanguage(activeLocale);
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async setUserData({ commit }, payload) {
      commit("SET_USER_DATA", payload);
    },
    async setEnterpriseHasEsgModule({ commit }, payload) {
      commit("SET_ENTERPRISE_HAS_ESG_MODULE", payload);
    },
    async setFirmHasEsgModule({ commit }, payload) {
      commit("SET_FIRM_HAS_ESG_MODULE", payload);
    },
    async addBranchTree({ commit }, payload) {
      commit("ADD_BRANCH_TREE", payload);
    },
    async removeBranchTree({ commit }, payload) {
      commit("REMOVE_BRANCH_TREE", payload);
    },
    async resetBranchTree({ commit }, payload) {
      commit("RESET_BRANCH_TREE", payload);
    },
    async setReferenceFirmId({ commit }, payload) {
      commit("SET_REFERENCE_FIRM_ID", payload);
    },
    async setReferenceEnterpriseId({ commit }, payload) {
      commit("SET_REFERENCE_ENTERPRISE_ID", payload);
    },
    async setUserOptions({ commit }, payload) {
      commit("SET_USER_OPTIONS", payload);
    },
    async setActiveLocale({ commit }, payload) {
      commit("SET_ACTIVE_LOCALE", payload);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
