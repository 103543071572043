import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "api/esg/eligibility-tests";

export function get(params) {
  const query = getQuery(params);
  return axiosClient.get(baseUrl, query);
}

export function getQuestionnaires(eligibilityTestId, params) {
  const query = getQuery(params);
  return axiosClient.get(`${baseUrl}/${eligibilityTestId}/questionnaires`, {
    ...query,
    timeout: 60000,
  });
}
