import { BalanceApi } from "@/api";
import i18n from "@/i18n";
import router from "@/router";
import { showSuccessSnackbar } from "@/utilities/snackbar";
import { JsonUtils } from "@/utilities/jsonUtils";

export async function getSchema(params) {
  const { data } = await BalanceApi.getSchema(params);
  return data;
}

export async function getConstraints() {
  const { data } = await BalanceApi.getConstraints();
  return data;
}

export async function getAddons() {
  const { data } = await BalanceApi.getAddons();
  return data;
}

export async function download(params) {
  let data;
  try {
    ({ data: data } = await BalanceApi.download(params));
  } catch (ex) {
    if (ex.response.status === 404) {
      // TODO: check if it's the correct move
      router.push({ name: "balances" });
    }
  }
  return data;
}

export async function get(id) {
  const { data } = await BalanceApi.get(id);
  return data;
}

export async function getByEnterpriseId(id) {
  let data;
  try {
    ({ data: data } = await BalanceApi.getByEnterpriseId(id));
  } catch (ex) {
    if (ex.response.status === 404) {
      // TODO: check if it's the correct move
      router.push({ name: "users" });
    }
  }
  return data;
}

export async function getBlobByHash(hash) {
  let { data } = await BalanceApi.getBlobByHash(hash);
  data = JSON.parse(data, JsonUtils.infinityReviver);
  return data;
}

export async function save(balance) {
  await BalanceApi.save(balance);
  showSuccessSnackbar(200, i18n.t("balance-saved-correctly"));
}

export async function remove(id) {
  await BalanceApi.remove(id);
  showSuccessSnackbar(200, i18n.t("balance-deleted-correctly"));
}

export async function createBalanceObject(formData) {
  const { data } = await BalanceApi.createBalanceObject(formData);
  return data;
}
