import Axios from "axios";
import Store from "@/store";
import Router from "@/router";
import { snackBarMapError } from "@/utilities/snackbar";
import i18n from "@/i18n";

const axiosConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 60000,
};

const axiosClient = Axios.create(axiosConfig);

axiosClient.interceptors.request.use((config) => {
  const token = Store.state.user.userToken;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    const errorPayload = snackBarMapError(error);

    if (errorPayload.code === 401) {
      Store.dispatch("user/resetState");
      if (!["login", "accountant-login"].includes(Router.currentRoute.name)) {
        Router.push({ name: "login" });
      } else {
        errorPayload.message = i18n.t("wrong-credentials");
      }
    } else if (errorPayload.code === 403) {
      Router.push({ name: "dashboard" });
    }

    Store.dispatch("snackbar/setSnackbar", errorPayload);
    return Promise.reject(error);
  }
);

export { axiosClient };
